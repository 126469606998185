import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Loading from "../Loading";

const Jobs = () => {
  const [jobs, setJobs] = useState([]);
  const [displayjobs, setDisplayjobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const { admin } = useAuth();
  const categoryRef = useRef();
  const QRef = useRef();
  const TypeRef = useRef();

  useEffect(() => {
    fetch("https://right-job.glitch.me/Jobs")
      .then((res) => res.json())
      .then((data) => {
        setJobs(data);
        setDisplayjobs(data);
        setLoading(true);
      });
  }, []);

  const handleSearch = (e) => {
    const searchCategory = categoryRef.current.value;
    const searchType = TypeRef.current.value;
    const searchQ = QRef.current.value;
    const matched = jobs.filter(
      (jobs) =>
        jobs.category.toLowerCase().includes(searchCategory.toLowerCase()) &&
        jobs.type.toLowerCase().includes(searchType.toLowerCase()) &&
        jobs.qualification.toLowerCase().includes(searchQ.toLowerCase())
    );

    setDisplayjobs(matched);
    setCurrentPage(1);

    e.preventDefault();
  };

  const jobC = jobs.map((job) => job?.category);

  const uniqueC = jobC.filter((value, index) => {
    return jobC.indexOf(value) === index;
  });

  const jobQ = jobs.map((job) => job?.qualification);

  const uniqueQ = jobQ.filter((value, index) => {
    return jobQ.indexOf(value) === index;
  });

  const jobT = jobs.map((job) => job?.type);

  const uniqueT = jobT.filter((value, index) => {
    return jobT.indexOf(value) === index;
  });

  const handleDeleteJob = (id) => {
    const proceed = window.confirm("Are you sure, you want to delete?");
    if (proceed) {
      fetch(`https://right-job.glitch.me/Jobs/${id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount > 0) {
            alert("Deleted successfully");
            const remainingStudents = jobs.filter((re) => re._id !== id);
            setJobs(remainingStudents);
            window.location.reload();
          }
        });
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const JobsPerPage = 3;

  // Calculate the index of the first and last product for the current page
  const indexOfLastJobs = currentPage * JobsPerPage;
  const indexOfFirstCandidate = indexOfLastJobs - JobsPerPage;
  const GG = displayjobs.slice(indexOfFirstCandidate, indexOfLastJobs);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);




  return (
    <div>
      <div
        className="gg"
        style={{
          marginTop: "-48px",
          backgroundImage: `linear-gradient(rgba(5, 10, 30, 0.6), rgba(5, 10, 30, 0.6)), url(img/job.png)`,
        }}
      >
        <p className="text-start fw-bold text-light mt-5 pt-5 ms-lg-5 ps-lg-5 ps-3">
          Home &#187; Jobs
        </p>
        <h2 className="text-start fw-bold text-light ms-lg-5 ps-lg-5 ps-3 mt-lg-4">
          JOBS
        </h2>
      </div>

      <form
        className="row justify-content-center mt-5 mx-0"
        onSubmit={handleSearch}
      >
        <div className="col-lg-3">
          <select
            className="mb-4 form-select rounded-0 p-3"
            ref={categoryRef}
            aria-label="Default select example"
          >
            <option style={{ fontWeight: "600" }} value={window.location.reload}>All Category</option>
            {uniqueC.map((value) => (
              <option value={value}>{value}</option>
            ))}
          </select>
        </div>
        <div className="col-lg-3">
          <select
            className="mb-4 form-select rounded-0 p-3"
            ref={QRef}
            aria-label="Default select example"
          >
            <option style={{ fontWeight: "600" }} value={window.location.reload}>All Qualification</option>
            {uniqueQ.map((value) => (
              <option value={value}>{value}</option>
            ))}
          </select>
        </div>
        <div className="col-lg-3">
          <select
            className="mb-4 form-select rounded-0 p-3"
            ref={TypeRef}
            aria-label="Default select example"
          >
            <option style={{ fontWeight: "600" }} value={window.location.reload}>All Types</option>
            {uniqueT.map((value) => (
              <option value={value}>{value}</option>
            ))}
          </select>
        </div>
        <div className="col-lg-1">
          <input
            value="Search"
            type="submit"
            className="btn btn-dark px-3 py-2 mt-2 rounded-0"
          />
        </div>
      </form>

      <div className="row my-5 mx-0 me-2 gap-5 align-items-center justify-content-center">
        {loading ? (
          displayjobs.length === 0 ? (
            <img style={{ width: "350px" }} src="img/No data.png" alt="" />
          ) : (
            GG.map((displayjob) => (
              <div
                key={displayjob._id}
                className="col-lg-3 col-md-4 col-sm-6 col-11 mb-3 card g-0 justify-content-center ms-2 ms-lg-0 rounded-0"
              >
                <div className="row justify-content-center">
                  <div className="col-lg-12 row justify-content-start mb-lg-2 mb-3">
                    <h6
                      style={{
                        borderBottom: "1px solid lightgray",
                        borderRight: "1px solid lightgray",
                        fontSize: '13px'
                      }}
                      className="py-1 px-0 col-lg-5 col-5 text-danger"
                    >
                      {displayjob?.type}
                    </h6>
                  </div>

                  <div className="col-lg-12">
                    <img
                      style={{ height: "200px" }}
                      src={
                        displayjob.img
                          ? displayjob.img
                          : "https://png.pngtree.com/png-vector/20190223/ourmid/pngtree-profile-line-black-icon-png-image_691051.jpg"
                      }
                      className="img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="col-lg-9 text-center">
                    <h5
                      style={{ fontFamily: "Lexend" }}
                      className="text-primary fw-bold my-3"
                    >
                      {displayjob?.title}
                    </h5>
                    
                    <p>
                      <i className="fas fa-map-marker-alt"></i>{" "}
                      {displayjob?.location}
                    </p>
                    <p>
                      <i className="fas fa-graduation-cap"></i>{" "}
                      {displayjob?.qualification}
                    </p>
                    <p className="my-3 fw-bold"> Vacancy : 
                     <span className="text-info ms-1"> {displayjob?.vacancy} </span>
                    </p>
                    <p>
                      <Link
                        style={{ color: "#11B719" }}
                        className="text-decoration-none"
                        to={`/Job/${displayjob._id}`}
                      >
                        Browse Job &#8594;
                      </Link>

                      {admin && (
                        <button
                          style={{ marginTop: "-5px" }}
                          className="btn bg-light btn-danger border-0 text-danger"
                          onClick={() => handleDeleteJob(displayjob._id)}
                        >
                          Delete
                        </button>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            ))
          )
        ) : (
          <Loading></Loading>
        )}
        <div className="d-flex justify-content-center">
          <p className="mt-1"> Pages - </p>
          {Array.from({
            length: Math.ceil(displayjobs.length / JobsPerPage),
          }).map((_, index) => (
            <h5 className="text-center">
              <button
                style={{
                  backgroundColor:
                    currentPage === index + 1 ? "#0C6DFD" : "white",
                  color: currentPage === index + 1 ? "white" : "#0C6DFD",
                }}
                className="btn btn-primary ms-2 px-3 py-1 rounded-0"
                key={index}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </button>
            </h5>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Jobs;
