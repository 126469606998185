import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Register from "./Components/Login/Register/Register";
import Login from "./Components/Login/Login/Login"
import AuthProvider from "./contexts/AuthProvider/AuthProvider";
import ResponsiveNav from "./Components/ResponsiveNav/ResponsiveNav";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Home/Home";
import AddBanner from "./Components/Header/AddBanner";
import Contact from "./Components/Contact/Contact";
import NotFound from "./Components/NotFound/NotFound"
import Jobs from "./Components/Jobs/Jobs";
import AddJob from "./Components/Jobs/AddJob";
import Job from "./Components/Jobs/Job";
import Reviews from "./Components/Reviews/Reviews";
import AdminRoute from "./Components/Login/PrivateRoute/AdminRoute";
import Candidate from "./Components/Candidate/Candidate";

function App() {

  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <ResponsiveNav></ResponsiveNav>
          <Switch>
            <Route exact path='/'>
              <Home></Home>
            </Route>
            <Route path='/Home'>
              <Home></Home>
            </Route>
            <Route path='/Jobs'>
              <Jobs></Jobs>
            </Route>

            <Route path='/Reviews'>
              <Reviews></Reviews>
            </Route>

            <AdminRoute path='/Candidate'>
              <Candidate></Candidate>
            </AdminRoute>

            <Route path="/Job/:JobId">
              <Job></Job>
            </Route>

            <AdminRoute path='/AddBanner'>
              <AddBanner></AddBanner>
            </AdminRoute>

            <AdminRoute path='/AddJob'>
              <AddJob></AddJob>
            </AdminRoute>

            <Route path="/Contact">
              <Contact></Contact>
            </Route>

            <Route path="/Register">
              <Register></Register>
            </Route>
            <Route path="/Login">
              <Login></Login>
            </Route>

            <Route path="*">
              <NotFound></NotFound>
            </Route>
            
          </Switch>
          <Footer></Footer>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
