import React from 'react';

const MyComponent = ({ data }) => {
  // Split the data into lines

  const lines = data?.split('.') || [''];

  return (
    <div>
      {lines.map((line, index) => (
        // Render each line as a new paragraph with a bullet point
        <p style={{color: '#667488', fontFamily: 'Lexend'}} key={index}>
          <span style={{ marginRight: '7px' }}>&bull;</span>
          {line}
        </p>
      ))}
    </div>
  );
};

export default MyComponent;