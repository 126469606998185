import React from "react";
import { useForm, Controller } from "react-hook-form";
import useAuth from "../../hooks/useAuth";
import Rating from "react-rating-stars-component";

const AddReview = () => {
  const { register, handleSubmit, reset, control } = useForm();
  const { user } = useAuth();

  const onSubmit = (data) => {
    data.name = user?.displayName;
    data.email = user?.email;

    fetch("https://right-job.glitch.me/Reviews", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.insertedId) {
          alert("Review Submitted");
          reset();
        } else {
          alert("You Already submitted review");
        }
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row text-start justify-content-start mx-0 mt-lg-5">
        <h4 style={{ fontFamily: "Lexend", color: '#262E45' }} className="fw-bold text-start mb-5">
          Submit a Review
        </h4>
        <div className="col-lg-8 mb-3">
          <label className="fw-bold">Rating *</label>
          <Controller
            control={control}
            name="rating"
            render={({ field }) => (
              <Rating
                {...field}
                count={5}
                size={30}
                onChange={(rating) => field.onChange(rating)}
              />
            )}
          />
        </div>
        <div className="col-lg-8">
          <label className="form-label fw-bold">Name *</label>
          <input
            className="mb-3 form-control"
            type="text"
            disabled
            value={user?.displayName}
          />
        </div>

        <div className="col-lg-10">
          <label className="form-label fw-bold">Review *</label>
          <textarea
            required
            type="text"
            rows="5"
            maxLength="280"
            placeholder="Write a Comment"
            className="mb-4 form-control"
            {...register("description")}
          />
          <input className="btn btn-primary" value="Submit" type="submit" />
        </div>
      </div>
    </form>
  );
};

export default AddReview;
