import React, { useEffect, useState } from "react";
import Loading from "../Loading";
import Applied from "./Applied";
import { useRef } from "react";

const Candidate = () => {
  const [displayCandidates, setDisplayCandidates] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(false);
  const industryRef = useRef();
  const QRef = useRef();
  const TitleRef = useRef();

  const handleSearchC = (e) => {
    const searchTitle = TitleRef.current.value;
    const searchIndustry = industryRef.current.value;
    const searchQ = QRef.current.value;
    const matched = candidates.filter(
      (jobs) =>
        jobs.title.toLowerCase().includes(searchTitle.toLowerCase()) &&
        jobs.industry.toLowerCase().includes(searchIndustry.toLowerCase()) &&
        jobs.qualification.toLowerCase().includes(searchQ.toLowerCase())
    );

    setDisplayCandidates(matched);
    setCurrentPage(1);
    e.preventDefault();
  };

  const jobT = candidates.map((job) => job?.title);

  const uniqueT = jobT.filter((value, index) => {
    return jobT.indexOf(value) === index;
  });

  const jobQ = candidates.map((job) => job?.qualification);

  const uniqueQ = jobQ.filter((value, index) => {
    return jobQ.indexOf(value) === index;
  });

  const jobI = candidates.map((job) => job?.industry);

  const uniqueI = jobI.filter((value, index) => {
    return jobI.indexOf(value) === index;
  });

  useEffect(() => {
    fetch("https://right-job.glitch.me/Apply")
      .then((res) => res.json())
      .then((data) => {
        setCandidates(data);
        setDisplayCandidates(data);
        setLoading(true);
      });
  }, []);

  const handleDeleteJob = (id) => {
    const proceed = window.confirm("Are you sure, you want to delete?");
    if (proceed) {
      fetch(`https://right-job.glitch.me/Apply/${id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount > 0) {
            alert("Deleted successfully");
            const remainingStudents = candidates.filter((re) => re._id !== id);
            setCandidates(remainingStudents);
            window.location.reload();
          }
        });
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const CandidatesPerPage = 3;

  // Calculate the index of the first and last product for the current page
  const indexOfLastCandidate = currentPage * CandidatesPerPage;
  const indexOfFirstCandidate = indexOfLastCandidate - CandidatesPerPage;
  const Candidates = displayCandidates.slice(
    indexOfFirstCandidate,
    indexOfLastCandidate
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="row text-start justify-content-center mb-5 mx-0 gap-5">
      <div
        className="gg"
        style={{
          backgroundImage: `linear-gradient(rgba(5, 10, 30, 0.6), rgba(5, 10, 30, 0.6)),url(img/applied.png)`,
        }}
      >
        <p className="text-start fw-bold text-light mt-4 pt-5 ms-lg-5 ps-lg-5">
          Home &#187; Candidates
        </p>
        <h2 className="text-start fw-bold text-light ms-lg-5 ps-lg-5">
          Candidates
        </h2>
      </div>
      <form
        className="row justify-content-center mx-0"
        onSubmit={handleSearchC}
      >
        <div className="col-lg-3">
          <select
            className="mb-4 form-select rounded-0 p-3"
            ref={TitleRef}
            aria-label="Default select example"
          >
            <option
              style={{ fontWeight: "600" }}
              value={window.location.reload}
            >
              All Catagories
            </option>
            {uniqueT.map((value) => (
              <option value={value}>{value}</option>
            ))}
          </select>
        </div>
        <div className="col-lg-3">
          <select
            className="mb-4 form-select rounded-0 p-3"
            ref={QRef}
            aria-label="Default select example"
          >
            <option
              style={{ fontWeight: "600" }}
              value={window.location.reload}
            >
              All Qualification
            </option>
            {uniqueQ.map((value) => (
              <option value={value}>{value}</option>
            ))}
          </select>
        </div>
        <div className="col-lg-3">
          <select
            className="mb-4 form-select rounded-0 p-3"
            ref={industryRef}
            aria-label="Default select example"
          >
            <option
              style={{ fontWeight: "600" }}
              value={window.location.reload}
            >
              All Industry
            </option>
            {uniqueI.map((value) => (
              <option value={value}>{value}</option>
            ))}
          </select>
        </div>
        <div className="col-lg-1">
          <input
            value="Search"
            type="submit"
            className="btn btn-dark shadow-lg px-3 py-2 mt-2 rounded-0"
          />
        </div>
      </form>
      {loading ? (
        displayCandidates.length === 0 ? (
          <img style={{ width: "350px" }} src="img/No data.png" alt="" />
        ) : (
          Candidates.map((gg) => (
            <Applied key={gg._id} gg={gg} handleDeleteJob={handleDeleteJob}></Applied>
          ))
        )
      ) : (
        <Loading></Loading>
      )}
      <div className="d-flex justify-content-center">
        <p className="mt-1"> Pages - </p>
        {Array.from({
          length: Math.ceil(displayCandidates.length / CandidatesPerPage),
        }).map((_, index) => (
          <h5 className="text-center">
            <button
              style={{
                backgroundColor:
                  currentPage === index + 1 ? "#0C6DFD" : "white",
                color: currentPage === index + 1 ? "white" : "#0C6DFD",
              }}
              className="btn btn-outline-primary ms-2 px-3 py-1 rounded-0"
              key={index}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </button>
          </h5>
        ))}
      </div>
    </div>
  );
};

export default Candidate;
