import React from "react";
import useAuth from "../../hooks/useAuth";

const Applied = ({ gg, handleDeleteJob }) => {
  const { admin } = useAuth();
  const base64PdfData = gg?.pdf;

  const downloadPdf = () => {
    const blobData = atob(base64PdfData);
    const arrayBuffer = new ArrayBuffer(blobData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < blobData.length; i++) {
      uint8Array[i] = blobData.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "downloaded_file.pdf";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  return (
    <div
      style={{
        borderTop: "3px solid orange",
        borderBottom: "3px solid orange",
        borderLeft: "3px solid #4EC674",
        borderRight: "3px solid #4EC674",
        fontSize: "14px",
        fontFamily: 'Itim',
        minHeight: '550px'
      }}
      className="row col-lg-3 col-11 pt-3  justify-content-center"
    >
      <div className="col-lg-4 col-4" style={{ fontWeight: "700", textAlign: 'right'}}>
        <p>Candidate :</p>
        <p>Phone :</p>
        <p>Gender :</p>
        <p>Job Type :</p>
        <p>Job Title :</p>
        <p>Location :</p>
        {gg?.location.length > 36 ? <br /> : ""}
        <p>Industry :</p>
        <p>Education:</p>
        <p>Course:</p>
        <p>Experience:</p>
        <p>Salary:</p>
        <p>End Date :</p>
      </div>

      <div className="col-lg-7 col-7 pe-0 ps-2" style={{marginRight: '-25px'}}>
        <p>{gg?.name}</p>
        <p>{gg?.phone}</p>
        <p>{gg?.gender}</p>
        <p>{gg?.type}</p>
        <p>{gg?.title}</p>
        <p>{gg?.location}</p>
        <p>{gg?.industry}</p>
        <p>{gg?.qualification}</p>
        {gg?.course? <p>{gg?.course}</p> : <p>Not Required</p>}
        <p>{gg?.experience}</p>
        <p>{gg?.salary}</p>
        <p>{gg?.end}</p>
      </div>
      <div className="col-lg-8 col-9 ms-5 ms-lg-3">
        <button className="btn btn-primary rounded-0" onClick={downloadPdf}>
          Download CV
        </button>

        {admin && (
          <button
            className="btn btn-outline-danger ms-1 rounded-0"
            onClick={() => handleDeleteJob(gg._id)}
          >
            X
          </button>
        )}
      </div>
    </div>
  );
};

export default Applied;
