import {
  Container,
  TextField,
  Button,
  CircularProgress,
  Alert
} from "@mui/material";
import React, { useState } from "react";
import { Grid } from "@mui/material";
import { NavLink, useHistory } from "react-router-dom";
import useAuth from "./../../../hooks/useAuth";

const Register = () => {
  const [loginData, setLoginData] = useState({});
  const history = useHistory();
  const { user, registerUser, isLoading, authError } = useAuth();

  const handleOnBlur = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newLoginData = { ...loginData };
    newLoginData[field] = value;
    setLoginData(newLoginData);
  };
  const handleLoginSubmit = (e) => {
    if (loginData.password !== loginData.password2) {
      alert("Your password did not match");
      return;
    }
    if (loginData.role === "") {
      alert("Error: Please select an option");
    }

    registerUser(loginData.email, loginData.password, loginData.name, loginData.role, history);
    e.preventDefault();
  };
  return (
    <Container className="mb-5 mt-4">
      <NavLink
        style={{ textDecoration: "none", fontFamily: "Alata" }}
        to="/login"
      >
        <button className="btn btn-secondary rounded-0">
          Login
        </button>
      </NavLink>
      <NavLink
        style={{ textDecoration: "none", fontFamily: "Alata" }}
        to="/Register"
      >
        <button className="btn btn-success rounded-0">
          Register
        </button>
      </NavLink>
      
      <Grid alignItems="center" container>
        <Grid item xs={12} md={6}>
          <img
            style={{ width: "100%" }}
            src="https://img.freepik.com/vector-gratis/ilustracion-dibujos-animados-usuario-femenino-que-ingresa_241107-682.jpg?size=626&ext=jpg"
            alt=""
          />
        </Grid>
        <Grid item xs={12} md={6}>
        <h3 style={{fontFamily: 'Pacifico'}} className="text-primary">
            Register
          </h3>
          {!isLoading && (
            <form onSubmit={handleLoginSubmit}>
              <TextField
                sx={{ width: "75%", m: 1 }}
                id="standard-basic"
                label="Your Name"
                name="name"
                onBlur={handleOnBlur}
                variant="standard"
              />

              <select
                style={{
                  borderTop: "0px",
                  borderLeft: "0px",
                  borderRight: "0px",
                  borderRadius: '0px',
                  width: "75%",
                  display: "inline-flex",
                  justifyContent: "center",
                }}
                name="role"
                onBlur={handleOnBlur}
                required
                className="mb-2 mt-4 form-select me-0 ps-0"
                aria-label="Default select example"
              >
                <option value="">Choose One</option>
                <option value="candidate">Candidate</option>
                <option value="employer">Employer</option>
              </select>

              <TextField
                sx={{ width: "75%", m: 1 }}
                id="standard-basic"
                label="Your Email"
                name="email"
                type="email"
                onBlur={handleOnBlur}
                variant="standard"
              />
              <TextField
                sx={{ width: "75%", m: 1 }}
                id="standard-basic"
                label="Your Password"
                type="password"
                name="password"
                onBlur={handleOnBlur}
                variant="standard"
              />
              <TextField
                sx={{ width: "75%", m: 1 }}
                id="standard-basic"
                label="ReType Your Password"
                type="password"
                name="password2"
                onBlur={handleOnBlur}
                variant="standard"
              />

              <Button
                sx={{ width: "75%", mt: 3 }}
                type="submit"
                variant="contained"
              >
                Register
              </Button>
              <NavLink style={{ textDecoration: "none", fontFamily: 'Alata' }} to="/Login">
              <button className="btn text-dark mt-3">
              Already Registered ? <span className="text-primary ms-2">Please Login</span>
            </button>
              </NavLink>
            </form>
          )}
          {isLoading && <CircularProgress />}
          {user?.email && (
            <Alert severity="success">User Created successfully!</Alert>
          )}
          {authError && <Alert severity="error">{authError}</Alert>}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Register;
