import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useAuth from './../../../hooks/useAuth';

const AdminRoute = ({ children, ...rest }) => {
    const { admin, employer,isLoading } = useAuth();
    if (isLoading) { return <img width="40%" src="/img/loading.gif" alt=""/>}
    return (
        <Route
            {...rest}
            render={({ location }) =>
            admin || employer ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            state: { from: location },
                            pathname: "/Login"
                        }}
                    />
                )
            }
        />
    );
};

export default AdminRoute;