import React from "react";
import Header from "../Header/Header";
import HomeJobs from "./HomeJobs";
import HomeReviews from "./HomeReviews";
import { Link } from "react-router-dom";
import "./home.css";
import Fade from "react-reveal/Fade";

const Home = () => {
  return (
    <div>
      <Header></Header>
      
      <HomeJobs></HomeJobs>

      <div
        className="row justify-content-center align-items-center text-start mx-1 gap-5"
        style={{ marginTop: "100px" }}
      >
        <div className="col-lg-5 col-md-6 ms-lg-4">
          <Fade left>
            <h2 style={{ color: "#05264E", fontWeight: "700" }}>
              Best Job Search platform <br /> Experience for you
            </h2>
            <p style={{ fontSize: "14px" }} className="mt-3 mb-4">
              Work with talented people at the most affordable price to get the
              most out of your time and cost
            </p>
            <div className="row">
              <div className="col-lg-2 col-2" style={{ marginRight: "-15px" }}>
                <img className="w-75" src="img/right.jpg" alt="" />
              </div>
              <div className="col-lg-10 col-10 text-start">
                <h5 style={{ color: "#05264E", fontWeight: "600" }}>
                  No Extra Charges
                </h5>
                <p style={{ fontSize: "14px" }} className="text-secondary">
                  Interview potential fits for your job, negotiate rates, and
                  only pay for work you approve.
                </p>
              </div>
            </div>
            <div className="row my-lg-2">
              <div className="col-lg-2 col-2" style={{ marginRight: "-15px" }}>
                <img className="w-75" src="img/right.jpg" alt="" />
              </div>
              <div className="col-lg-10 col-10 text-start">
                <h5 style={{ color: "#05264E", fontWeight: "600" }}>
                  Safe and secure
                </h5>
                <p style={{ fontSize: "14px" }} className="text-secondary">
                  Focus on your work knowing we help protect your data and
                  privacy. We’re here with 24/7 support if you need it.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-2 col-2" style={{ marginRight: "-15px" }}>
                <img className="w-75" src="img/right.jpg" alt="" />
              </div>
              <div className="col-lg-10 col-10 text-start">
                <h5 style={{ color: "#05264E", fontWeight: "600" }}>
                  Proof of quality
                </h5>
                <p style={{ fontSize: "14px" }} className="text-secondary">
                  Check any pro’s work samples, client reviews, and identity
                  verification.
                </p>
              </div>
            </div>
          </Fade>
        </div>
        <div className="col-lg-4 col-md-5 mt-lg-4">
          <Fade right>
            <img className="w-100" src="img/pic1.png" alt="" />
          </Fade>
        </div>
      </div>

      <HomeReviews></HomeReviews>

      <div
        className="row justify-content-center align-items-center text-start mx-0 mt-5">
        <Fade left>
          <div
            className="col-lg-7 row justify-content-evenly mx-0 px-1"
            style={{ zIndex: "1" }}
          >
            <h2 style={{ color: "#05264E", fontWeight: "700" }}>
              Need something done?
            </h2>
            <p style={{ fontSize: "14px" }} className="mb-lg-5">
              Hire Experts & Get Your Any Job Done
            </p>
            <div
              className="row col-lg-3 col-6 col-md-4 mb-lg-3 justify-content-center pt-3 px-0 mx-0 gap-3"
              style={{
                boxShadow: "2px 1px 10px lightgray",
                backgroundColor: "white",
                borderRadius: "10px",
              }}
            >
              <div className="col-lg-5 col-6">
                <img className="w-100" src="img/1.png" alt="" />
              </div>
              <div className="col-lg-12 text-center">
                <h5 style={{ color: "#05264E", fontWeight: "600" }}>
                  Post a job
                </h5>
                <p style={{ fontSize: "14px" }} className="text-secondary">
                  It’s free to post a job. Simply fill in a Add Job Page.
                </p>
              </div>
            </div>
            <div
              className="row col-lg-3 col-6 col-md-4 mb-lg-3 justify-content-center pt-3 px-0 mx-0 gap-3"
              style={{
                boxShadow: "2px 1px 10px lightgray",
                backgroundColor: "white",
                borderRadius: "10px",
              }}
            >
              <div className="col-lg-5 col-6">
                <img className="w-100" src="img/2.png" alt="" />
              </div>
              <div className="col-lg-12 text-center">
                <h5 style={{ color: "#05264E", fontWeight: "600" }}>
                  Freelancers
                </h5>
                <p style={{ fontSize: "14px" }} className="text-secondary">
                  Connect to freelancers with proven business experience
                </p>
              </div>
            </div>
            <div
              className="row col-lg-3 col-6 col-md-4 mb-lg-3 mt-0 justify-content-center pt-3 px-0 mx-0 gap-3"
              style={{
                boxShadow: "2px 1px 10px lightgray",
                backgroundColor: "white",
                borderRadius: "10px",
              }}
            >
              <div className="col-lg-5 col-5">
                <img className="w-100" src="img/3.png" alt="" />
              </div>
              <div className="col-lg-12 text-center">
                <h5 style={{ color: "#05264E", fontWeight: "600" }}>
                  Pay safely
                </h5>
                <p style={{ fontSize: "14px" }} className="text-secondary">
                  Getting paid on time is the main priority for employees
                </p>
              </div>
            </div>
          </div>
        </Fade>
        <div
          className="col-lg-5 mt-lg-5 d-lg-block d-none"
          style={{ marginLeft: "-220px", zIndex: "0" }}
        >
          <Fade right>
            <img className="w-100 rounded-3" src="img/pic2.jpg" alt="" />
          </Fade>
        </div>
      </div>
      <div
        style={{ marginBottom: "-2px", marginTop: '70px' ,fontFamily: "Lexend" }}
        className="row justify-content-evenly align-items-center text-start pt-4 mx-0"
      >
        <Fade bottom>
        <div className="col-lg-5 mb-5">
          <h2 className="fw-bold">
            Find the talent needed to get your business growing.
          </h2>
          <p className="mt-3 mb-4">
            Advertise your jobs to thousands of monthly users and search
            thousands CVs
          </p>
          <Link to="/Contact">
            <button style={{textShadow: '1px 1px 2px #000', borderRadius: '2px'}} className="btn btn-success py-1 px-3 fs-5">
              Let's Find out
            </button>
          </Link>
        </div>
        <div className="col-lg-4">
          <img className="w-100" src="img/pic4.png" alt="" />
        </div>
        </Fade>
      </div>
      
    </div>
  );
};

export default Home;
