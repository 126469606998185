import {
  Container,
  TextField,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import useAuth from "./../../../hooks/useAuth";

const Login = () => {
  const [loginData, setLoginData] = useState({});
  const { user, loginUser, isLoading, authError } = useAuth();

  const location = useLocation();
  const history = useHistory();

  const handleOnChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newLoginData = { ...loginData };
    newLoginData[field] = value;
    setLoginData(newLoginData);
  };
  const handleLoginSubmit = (e) => {
    loginUser(loginData.email, loginData.password, location, history);
    e.preventDefault();
  };

  const ScrollToTop = () => {
    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }, []);

    return null;
  };



  return (
    <Container className="mb-5 mt-4">
      <NavLink
        style={{ textDecoration: "none", fontFamily: "Alata" }}
        to="/login"
      >
        <button className="btn btn-primary rounded-0">
          Login
        </button>
      </NavLink>
      <NavLink
        style={{ textDecoration: "none", fontFamily: "Alata" }}
        to="/Register"
      >
        <button className="btn btn-secondary rounded-0">
          Register
        </button>
      </NavLink>
      <Grid alignItems="center" container>

        <Grid item xs={12} md={6}>
          <img
            style={{ width: "100%" }}
            src="https://img.freepik.com/vector-gratis/ilustracion-dibujos-animados-usuario-femenino-que-ingresa_241107-682.jpg?size=626&ext=jpg"
            alt=""
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <h3 style={{ fontFamily: 'Pacifico' }} className="text-primary my-0">
            Login
          </h3>
          <form onSubmit={handleLoginSubmit}>
            <TextField
              sx={{ width: "75%", m: 1 }}
              id="standard-basic"
              label="Email"
              name="email"
              onChange={handleOnChange}
              variant="standard"
            />
            <TextField
              sx={{ width: "75%", m: 1 }}
              id="standard-basic"
              label="Password"
              type="password"
              name="password"
              onChange={handleOnChange}
              variant="standard"
            />

            <Button
              className="my-4"
              sx={{ width: "75%" }}
              type="submit"
              variant="contained"
            >
              Login
            </Button>
            <NavLink
              style={{ textDecoration: "none", fontFamily: "Alata" }}
              to="/Register"
            >
              <button className="btn text-dark">
                New User ?
                <span className="text-primary ms-2">Please Register</span>
              </button>
            </NavLink>
            {user?.email && (
              <Alert severity="success">Login successfully!</Alert>
            )}
            {authError && <Alert severity="error">{authError}</Alert>}
          </form>
          {isLoading && <CircularProgress />}
        </Grid>
      </Grid>
      <ScrollToTop />
    </Container>
  );
};

export default Login;
