import React, { useEffect, useState } from "react";
import { Rating } from "@mui/material";
import Fade from "react-reveal/Fade";

import Loading from "../Loading";

const HomeReviews = () => {
  const [homeReviews, sethomeReviews] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch("https://right-job.glitch.me/Reviews")
      .then((res) => res.json())
      .then((data) => {
        sethomeReviews(data);
        setLoading(true);
      });
  }, []);

  return (
    <div style={{ marginTop: "100px"}}>
      <div className="line"></div>
      <h4
        style={{
          color: "#262339",
          fontFamily: "Alata",
          display: "inline-block",
        }}
        className="fw-bold mx-1"
      >
        What Our Clients Say
      </h4>
      <div className="line"></div>
      <div className="my-5 row justify-content-center gap-lg-5 gap-md-4 mx-4">
        {loading ? (
          homeReviews
            .slice(Math.max(homeReviews.length - 3, 0))
            .map((review) => (
              <Fade key={review._id} bottom>
              <div
                style={{ boxShadow: "0px 2px 20px 1px lightgray", borderRadius: '10px' }}
                className="col-lg-3 col-md-5 text-start py-3 ps-4 border-0 mb-5">

                <Rating size="small" value={parseInt(review?.rating)} />
                <p style={{ color: "#A7A9AB" }}>{review?.description}</p>
                <div className="d-flex align-items-center">
                  <img
                    style={{ width: "50px" }}
                    className="rounded-circle"
                    src="https://t3.ftcdn.net/jpg/05/53/79/60/360_F_553796090_XHrE6R9jwmBJUMo9HKl41hyHJ5gqt9oz.jpg"
                    alt=""
                  />
                  <h6 className="fw-bold ms-2">{review?.name}</h6>
                </div>
              </div>
              </Fade>
            ))
        ) : (
          <Loading></Loading>
        )}
      </div>
    </div>
  );
};

export default HomeReviews;
