import React, { useEffect, useState } from "react";
import AddReview from "./AddReview";
import { Rating } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import Loading from "../Loading";
import "./share.css"


const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const { admin, user } = useAuth();

  useEffect(() => {
    fetch("https://right-job.glitch.me/Reviews")
      .then((res) => res.json())
      .then((data) => {setReviews(data); setLoading(true);});
  }, []);

  const handleDeleteUser = (id) => {
    const proceed = window.confirm("Are you sure, you want to delete?");
    if (proceed) {
      fetch(`https://right-job.glitch.me/Reviews/${id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount > 0) {
            alert("deleted successfully");
            const remainingReviews = reviews.filter((re) => re._id !== id);
            setReviews(remainingReviews);
          }
        });
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const JobsPerPage = 2;

  // Calculate the index of the first and last product for the current page
  const indexOfLastJobs = currentPage * JobsPerPage;
  const indexOfFirstCandidate = indexOfLastJobs - JobsPerPage;
  const GG = reviews.slice(indexOfFirstCandidate, indexOfLastJobs);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="row justify-content-center align-items-center mx-0 gap-5 mb-5">
      <div className="gg" style={{
          backgroundImage: `linear-gradient(rgba(5, 10, 30, 0.6), rgba(5, 10, 30, 0.6)),url(img/review.png)`,
        }}>
          <p className="text-start fw-bold text-light mt-4 pt-5 ms-lg-5 ps-lg-5">Home &#187; Reviews</p>
          <h2 className="text-start fw-bold text-light ms-lg-5 ps-lg-5">Reviews</h2>
      </div>
      <div className="col-lg-6">
       {user.email ? <AddReview></AddReview> : <div>
        <img className="w-50 mt-5" src="img/error_review.jpg" alt="" />
        <p className="text-danger mt-3">Please Sign in to Submit your Review.</p>
       </div> }
      </div>

      <div className="overflow-auto col-lg-5 col-11 px-0 mt-5" style={{height: '500px'}}>
        <h3
          style={{
            color: "#262E45",
            backgroundColor: '#f1f1f1',
            fontFamily: "Lexend",
          }}
          className="text-lg-start fw-bold mb-5 ms-lg-1 sticky-top py-2 px-2"
        >
          Customers Reviews
        </h3>
        {loading ? ( GG.map((review) => (
          <div key={review._id}
            className="col-lg-10 row px-0 mx-0 mb-5 justify-content-center">
            <div className="col-lg-2 col-4 mb-3">
              <img
                className="rounded-circle w-100"
                src="https://t3.ftcdn.net/jpg/05/53/79/60/360_F_553796090_XHrE6R9jwmBJUMo9HKl41hyHJ5gqt9oz.jpg"
                alt=""
              />
            </div>
            <div className="col-lg-10 px-0 text-lg-start">
              <h6 className="fw-bold ms-1">{review?.name}</h6>
              <Rating size="small" value={parseInt(review?.rating)} />
              <p style={{ color: "#A7A9AB" }}>
                {review?.description}
                {admin && (
                  <button
                    className="btn btn-outline-danger ms-2 py-0 px-2 rounded-0"
                    onClick={() => handleDeleteUser(review._id)}
                  >
                    Delete
                  </button>
                )}
              </p>
            </div>
          </div> ) 
        )): <Loading></Loading>}
        <div className="d-flex justify-content-center">
          <p className="mt-1"> Pages - </p>
          {Array.from({
            length: Math.ceil(reviews.length / JobsPerPage),
          }).map((_, index) => (
            <h5 className="text-center">
              <button
                style={{
                  backgroundColor:
                    currentPage === index + 1 ? "#0C6DFD" : "white",
                  color: currentPage === index + 1 ? "white" : "#0C6DFD",
                }}
                className="btn btn-primary ms-2 px-3 py-1 rounded-0"
                key={index}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </button>
            </h5>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Reviews;
