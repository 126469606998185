const firebaseConfig = {

  apiKey: "AIzaSyAXvWRl2WCbPahPjAL8tzyorVB0wDfrmLc",

  authDomain: "rightjob-00.firebaseapp.com",

  projectId: "rightjob-00",

  storageBucket: "rightjob-00.appspot.com",

  messagingSenderId: "283493305109",

  appId: "1:283493305109:web:7b2c18ed867703d964b71c",

  measurementId: "G-QZQ0W60RHF"

};
export default firebaseConfig;