import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import useAuth from "../../hooks/useAuth";
import { Button, Typography } from "@mui/material";
import axios from "axios";
import "./apply.css"

const ApplyModal = ({ job, handleApplyClose, openApply }) => {
  const { type, title, location, gender, industry, qualification, experience, salary, course, end } = job;
  const { user } = useAuth();
  const [pdf, setPdf] = useState(null);
  const [error, setError] = useState("");
  const [phone, setPhone] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("pdf", pdf);
    formData.append("phone", phone);
    formData.append("name", user?.displayName);
    formData.append("email", user?.email);
    formData.append("type", type);
    formData.append("title", title);
    formData.append("location", location);
    formData.append("gender", gender);
    formData.append("industry", industry);
    formData.append("qualification", qualification);
    formData.append("course", course);
    formData.append("experience", experience);
    formData.append("salary", salary);
    formData.append("end", end);

    axios.post("https://right-job.glitch.me/Apply", formData).then((res) => {
      if (res.data.insertedId) {
        alert("Applied SuccessFull");
        handleApplyClose();
      } else {
        alert("You Already Applied for this Job");
        handleApplyClose();
      }
    });
  };

  const handlePdf = (e) => {
    const file = e.target.files[0];
    if (file) {
      
      if (file.size <= 300 * 1024) {
        setPdf(file);
        setError("");
      } else {
        setPdf(null);
        setError("File size exceeds 300KB. Please choose a smaller file.");
      }
    }

    if(file){
      if (file.type === 'application/pdf') {
        setPdf(file)
        setError("");
      } else {
        setPdf(null);
        setError("Please Upload a Pdf File");
      }
    }

  };

  const handleChange = (e) => {
    setPhone(e.target.value);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openApply}
      onClose={handleApplyClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openApply}>
        <Box className="AM">
          <Typography
            className="text-center mb-4 fw-bold"
            id="transition-modal-title"
            variant="h6"
            component="h4"
            style={{ color: "#272f46" }}
          >
            Apply for this job
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              sx={{ width: "100%" }}
              className="mb-4"
              required
              id="outlined-size-small"
              name="name"
              label="Full Name"
              value={user?.displayName}
              size="small"
            />

            <TextField
              sx={{ width: "100%" }}
              className="mb-4"
              required
              id="outlined-size-small"
              value={user?.email}
              name="email"
              label="E-mail"
              size="small"
            />
            <TextField
              sx={{ width: "100%" }}
              className="mb-4"
              id="outlined-size-small"
              required
              placeholder="Phone Number"
              size="small"
              label="Phone"
              type="number"
              onChange={handleChange}
            />
            <input
              className="mb-4 form-control"
              sx={{ width: "100%" }}
              id="outlined-size-small"
              onChange={handlePdf}
              required
              size="small"
              type="file"
            />
            {error && <div style={{ color: 'red', marginBottom: '30px', textAlign: 'center', fontWeight: '600' }}>{error}</div>}

            {error? <Button disabled sx={{ width: "100%" }} type="submit" variant="contained">
              Apply
            </Button> : <Button sx={{ width: "100%" }} type="submit" variant="contained">
              Apply
            </Button> }
          </form>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ApplyModal;
