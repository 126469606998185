import axios from "axios";
import { useForm } from "react-hook-form";

const AddJob = () => {
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (data) => {
    axios
      .post("https://right-job.glitch.me/Jobs", data)
      .then((res) => {
        if (res.data.insertedId) {
          alert("Added SuccessFully");
          reset();
        }
      });
  };

  function getCurrentDate() {
    const today = new Date();
    let month = today.getMonth() + 1;
    let day = today.getDate();
  
    // Add leading zero if month or day is less than 10
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;
  
    const currentDate = `${today.getFullYear()}-${month}-${day}`;
    return currentDate;
  }

  return (
    <div className="my-5">
      <h3 style={{ color: "#262339" }} className="fw-bold">
        Add a New Job
      </h3>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row text-start justify-content-center px-2 px-lg-5 mx-0">
          <div className="col-lg-6">
            <label className="form-label">Image URL *</label>
            <input
              required
              className="mb-4 form-control"
              {...register("img")}
              placeholder="Image URL.."
            />
          </div>
          <div className="col-lg-6">
            <label className="form-label">Job Category *</label>
            <input
              required
              type="text"
              className="mb-4 form-control"
              {...register("category")}
              placeholder="Job Category"
              maxLength="25"
            />
          </div>
          <div className="col-lg-6">
            <label className="form-label">Job Title *</label>
            <input
              required
              type="text"
              className="mb-4 form-control"
              {...register("title")}
              placeholder="Enter Job Tilte"
              maxLength="25"
            />
          </div>
          <div className="col-lg-6">
            <label className="form-label">Location *</label>
            <input
              type="text"
              required
              maxLength="50"
              className="mb-4 form-control"
              {...register("location")}
              placeholder="Enter Location"
            />
          </div>

          <div className="col-lg-6">
            <label className="form-label">Vacancy *</label>
            <input
              type="number"
              required
              className="mb-4 form-control"
              {...register("vacancy")}
              placeholder="Number Of Vacancy"
            />
          </div>

          <div className="col-lg-6">
            <label className="form-label">Choose Gender *</label>
            <select
              className="mb-4 form-select"
              {...register("gender")}
              aria-label="Default select example"
            >
              <option value="Not Provided" selected>Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Male/Female">Male/Female</option>
            </select>
          </div>

          <div className="col-lg-6">
            <label className="form-label">Educational Qualification *</label>
            <input
              required
              type="text"
              className="mb-4 form-control"
              {...register("qualification")}
              placeholder="Required Qualification"
            />
          </div>

          <div className="col-lg-6">
            <label className="form-label">Experience *</label>
            <select
              className="mb-4 form-select"
              required
              {...register("experience")}
              aria-label="Default select example"
            >
              <option value="Not Provided" selected>Select Experience</option>
              <option value="Fresher">Fresher</option>
              <option value="1 year">1 Year</option>
              <option value="3 Years">3 Years</option>
              <option value="5 Years">5 Years</option>
            </select>
          </div>

          <div className="col-lg-6">
            <label className="form-label">Additional Coures</label>
            <input
              type="text"
              className="mb-4 form-control"
              {...register("course")}
              maxLength="25"
              placeholder="Enter Course Name"
            />
          </div>

          <div className="col-lg-6">
            <label className="form-label">Job Type *</label>
            <select required
              className="mb-4 form-select"
              {...register("type")}
              aria-label="Default select example"
            >
              <option value="Not Provided">Select Type</option>
              <option value="Full Time">Full Time</option>
              <option value="Part Time">Part Time</option>
              <option value="Full Time / Part Time">Full Time / Part Time</option>
            </select>
          </div>

          <div className="col-lg-6">
            <label className="form-label">Industry Name *</label>
            <input
              type="text"
              required
              maxLength="25"
              className="mb-4 form-control"
              {...register("industry")}
              placeholder="Name of Industry"
            />
          </div>

          <div className="col-lg-6">
            <label className="form-label"> Salary *</label>
            <input
              required
              type="text"
              className="mb-4 form-control"
              {...register("salary")}
              placeholder="Enter Amount"
            />
          </div>

          <div className="col-lg-6">
            <label className="form-label">Application ends *</label>
            <input
              type="date"
              min={getCurrentDate()}
              required
              className="mb-4 form-control"
              {...register("end")}
            />
          </div>

          <div className="col-lg-6">
            <label className="form-label">Job Description *</label>
            <textarea
              required
              type="text"
              rows="5"
              className="mb-4 form-control"
              placeholder="Enter the description for this job"
              {...register("description")}
            />
          </div>
        </div>
        <input
          className="btn btn-outline-success"
          value="Submit"
          type="submit"
        />
      </form>
    </div>
  );
};

export default AddJob;
